<template>
  <main>
    <v-card flat>
      <v-card-title class="d-flex align-center">
        <span>Assets Report</span>
        <v-btn icon class="ml-3" @click="getReport" :disabled="report.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>

        <v-spacer></v-spacer>

        <v-btn
          text
          outlined
          class="mr-3"
          @click="exportReport"
          :disabled="exportLoading"
        >
          <v-icon class="mr-1">{{ icons.mdiMicrosoftExcel }}</v-icon>
          Export
        </v-btn>
        <v-text-field
          width="300"
          dense
          outlined
          v-model="assetId"
          @blur="getReport({ page: 1 })"
          @keyup.enter="triggerBlurEvent($event)"
          clearable
          hide-details
          label="Filter Report By Asset ID"
          placeholder="Asset Id"
          class="me-3"
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          disabled
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': report.loading
          }"
          :items="report.list"
          :options.sync="report.options"
          :page="report.meta.page"
          :server-items-length="report.meta.total || 0"
          :loading="report.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { mdiReload, mdiMicrosoftExcel } from '@mdi/js'
import moment from 'moment'

export default {
  data: () => ({
    icons: {
      mdiReload,
      mdiMicrosoftExcel,
    },
    assetId: null,
    exportLoading: false,
    report: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    tableColumns() {
      return [
        { text: 'DATE', value: 'createdAt', align: 'start', sortable: false },
        { text: 'ASSET ID', value: 'asset._id', sortable: false },
        { text: 'ASSET', value: 'asset.name', sortable: false },
        { text: 'TYPE', value: 'asset.category', sortable: false },
        { text: 'BUYER', value: 'buyer.wallet', sortable: false },
        { text: 'REFERER', value: 'referer', sortable: false },
      ]
    },
  },
  created() {
    if (this.$route.query.assetId) {
      this.assetId = this.$route.query.assetId
    }
  },
  methods: {
    triggerBlurEvent(e) {
      e.target.blur()
    },
    getReport(query) {
      if (this.report.loading) return

      this.report.loading = true

      const params = {
        limit: this.report.options.itemsPerPage,
        query: JSON.stringify({
          asset: this.assetId,
          ...query,
        }),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/asset/report', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.report.meta = meta
          this.report.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.report.loading = false
        })
    },

    exportReport() {
      if (this.exportLoading) return

      this.exportLoading = false

      const params = {
        query: JSON.stringify({
          asset: this.assetId,
        }),
        sort: { createdAt: 'desc' },
        export: 'excel',
      }

      this.$http({
        method: 'get',
        url: '/asset/report',
        responseType: 'blob',
        params,
      })
        .then(res => {
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(res.data)
          link.download = `report_${new Date().getTime()}.xlsx`

          link.click()
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.exportLoading = false
        })
    },

    changePagination(pagination) {
      this.getReport({
        page: pagination.page,
      })
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
